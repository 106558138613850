/** Microsite Types & Themes */
import { css } from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { selectMicrosite } from 'store/modules/model';
import {
	Colors,
	Palette,
	Spacing,
	TextPalette,
	ThemeType,
	TypographyType,
} from './theme';
import { px, px2rem } from './utils';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { ModelMicrositeStyles } from 'types/epi';

type MicrositeFonts = {
	primaryFontFamily: string;
	secondaryFontFamily: string;
};

const getCssStyle = (o: TypographyType): any => {
	return css`
		font-family: ${o.fontFamily};
		font-weight: ${o.fontWeight};
		font-size: ${o.fontSize};
		line-height: ${o.lineHeight};
		letter-spacing: ${o.letterSpacing};
		text-transform: ${o.textTransform};
		${o.color && `color:${o.color};`};
	`;
};

const MicrositeThemeProvider = ({ children }: any) => {
	const isNarrowDisplay = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.desktop - 1,
	});
	const microsite = useSelector(selectMicrosite);
	const theme = microsite?.theme;

	const micrositeColors = {
		primary: theme?.colors.primaryColor,
		secondary: theme?.colors.secondaryColor,
		tertiary: theme?.colors.tertiaryColor,
		quaternary: theme?.colors.quaternaryColor,
		bgBreadcrumbs: '#F8F8F8',
		commonAccentHue: '#2B2B2B',
		commonBgColor: '#F2F2F2',
		black: '#000000',
		white: '#FFFFFF',
	} as Colors;

	const micrositePalette: Palette = {
		text: {
			primary: theme?.textPalette.primary, //'#454545',
			secondary: theme?.textPalette.secondary, //'#FFFFFF', used in header buttons on hover
			disabled: '#000000',
			error: '#E02C55',
			hint: '#8C8279',
		} as TextPalette,
	};

	const micrositeFonts = {
		primaryFontFamily: theme?.typographyType.primaryFontFamily,
		secondaryFontFamily: theme?.typographyType.secondaryFontFamily,
	} as MicrositeFonts;

	const ThemeMicrositeDesktop: ThemeType = {
		name: 'Theme Microsite Desktop',
		isMicrositeActive: true,
		colors: micrositeColors,
		palette: micrositePalette,
		typography: {
			style1: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 700,
				fontSize: px2rem(48),
				lineHeight: px2rem(57),
				letterSpacing: '0',
			},
			style2: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 700,
				fontSize: px2rem(32),
				lineHeight: px2rem(48),
				letterSpacing: '0',
			},
			style3: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 700,
				fontSize: px2rem(24),
				lineHeight: px2rem(36),
				letterSpacing: '0',
			},
			style4: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 500,
				fontSize: px2rem(20),
				lineHeight: px2rem(32),
				letterSpacing: '0',
			},
			style5: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(18),
				lineHeight: px2rem(28),
				letterSpacing: '0',
			},
			style6: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 500,
				fontSize: px2rem(18),
				lineHeight: px2rem(27),
				letterSpacing: '0',
			},
			style7: {
				// preamble
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(24),
				lineHeight: px2rem(36),
				letterSpacing: '0',
			},
			style8: {
				// paragraph
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.secondaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(16),
				lineHeight: px2rem(24),
				letterSpacing: '0',
			},
			style9: {
				// Byline (SimplePuff,GrantPuff,StandardPuff)

				getStyle() {
					return getCssStyle(this);
				},
				fontFamily: micrositeFonts.secondaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(16),
				lineHeight: px2rem(24),
				letterSpacing: '0',
			},
			style10: {
				// EXTRA
				getStyle() {
					return getCssStyle(this);
				},
				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(18),
				lineHeight: px2rem(28),
				letterSpacing: '0',
			},
			style11: {
				// EXTRA, Button text, Grant Puff
				getStyle() {
					return getCssStyle(this);
				},
				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(18),
				lineHeight: px2rem(27),
				letterSpacing: '0',
			},
			style12: {
				// EXTRA, Menu desktop, second level menuitems, legends
				getStyle() {
					return getCssStyle(this);
				},
				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 500,
				fontSize: px2rem(18),
				lineHeight: px2rem(27),
				letterSpacing: '0',
			},
			style13: {
				// EXTRA, figcaptions (image captions / bildtexter in RTE/TinyMCE)
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.secondaryFontFamily,
				fontSize: px2rem(14),
				lineHeight: '1.4',
				letterSpacing: '0',
			},
		},
		spacing: {
			getSection() {
				return this.space5;
			},
			getElement() {
				return this.space4;
			},
			getTextAndElement() {
				return this.space3;
			},
			getText() {
				return this.space1;
			},
			getPageTopPadding() {
				// Page top padding
				return this.space6;
			},
			space1: px(8), // Between texts
			space2: px(16),
			space3: px(24), // Between texts and elements that should be close
			space4: px(32),
			space5: px(40), // Between Elements
			space6: px(80),
			space7: px(96), // Between Blocks
			space8: px(144), // Between last block and footer
		},
	};

	const ThemeMicrositeMobile: ThemeType = {
		name: 'Theme Microsite Mobile',
		isMicrositeActive: true,
		colors: micrositeColors,
		palette: micrositePalette,
		typography: {
			style1: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 700,
				fontSize: px2rem(32),
				lineHeight: px2rem(41),
				letterSpacing: '0',
			},
			style2: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 700,
				fontSize: px2rem(24),
				lineHeight: px2rem(36),
				letterSpacing: '0',
			},
			style3: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 700,
				fontSize: px2rem(24),
				lineHeight: px2rem(36),
				letterSpacing: '0',
			},
			style4: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(18),
				lineHeight: px2rem(24),
				letterSpacing: '0',
			},
			style5: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 500,
				fontSize: px2rem(16),
				lineHeight: px2rem(20),
				letterSpacing: '0',
			},
			style6: {
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(18),
				lineHeight: px2rem(28),
				letterSpacing: px2rem(0),
			},
			style7: {
				// preamble
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(20),
				lineHeight: px2rem(30),
				letterSpacing: '0',
			},
			style8: {
				// paragraph
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.secondaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(16),
				lineHeight: px2rem(24),
				letterSpacing: '0',
			},
			style9: {
				// Byline (SimplePuff,GrantPuff,StandardPuff)

				getStyle() {
					return getCssStyle(this);
				},
				fontFamily: micrositeFonts.secondaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(16),
				lineHeight: px2rem(24),
				letterSpacing: '0',
			},
			style10: {
				// EXTRA
				getStyle() {
					return getCssStyle(this);
				},
				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(18),
				lineHeight: px2rem(28),
				letterSpacing: '0',
			},
			style11: {
				// EXTRA, Button text, Grant Puff
				getStyle() {
					return getCssStyle(this);
				},
				fontFamily: micrositeFonts.primaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(18),
				lineHeight: px2rem(27),
			},
			style12: {
				// EXTRA, Menu desktop, second level menuitems, legends
				getStyle() {
					return getCssStyle(this);
				},
				fontFamily: micrositeFonts.secondaryFontFamily,
				fontWeight: 400,
				fontSize: px2rem(18),
				lineHeight: px2rem(27),
				letterSpacing: '0',
			},
			style13: {
				// EXTRA, figcaptions (image captions / bildtexter in RTE/TinyMCE)
				getStyle() {
					return getCssStyle(this);
				},

				fontFamily: micrositeFonts.secondaryFontFamily,
				fontSize: px2rem(14),
				lineHeight: '1.4',
				letterSpacing: '0',
			},
		},
		spacing: {
			getSection() {
				return this.space5;
			},
			getElement() {
				return this.space4;
			},
			getTextAndElement() {
				return this.space3;
			},
			getText() {
				return this.space1;
			},
			getPageTopPadding() {
				// Page top padding
				return this.space5;
			},
			space1: px(8), // Between texts
			space2: px(16),
			space3: px(24), // Between texts and elements that should be close
			space4: px(32),
			space5: px(40), // Between Elements
			space6: px(80),
			space7: px(96), // Between Blocks
			space8: px(144), // Between last block and footer
		},
	};

	return (
		<ThemeProvider
			theme={isNarrowDisplay ? ThemeMicrositeMobile : ThemeMicrositeDesktop}
		>
			{children}
		</ThemeProvider>
	);
};

export default MicrositeThemeProvider;
